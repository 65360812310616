import React from "react";
import { Link } from "react-router-dom";
import logo from "../logo.png"; // Replace with the path to your logo image
import "../App.css"; // This is where you would put your CSS if you're not using inline styles

const Header = () => {
  return (
    <header style={styles.headerStyle}>
      <Link to="/" style={styles.navLinks}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt="Logo" style={styles.logoStyle} />
          <h1>Hacky Apps</h1>
        </div>
      </Link>
      {/* <div style={styles.iconsStyle}>
        <i className="fab fa-twitter" style={styles.iconStyle}></i>
        <i className="fab fa-facebook-f" style={styles.iconStyle}></i>
        <i className="fab fa-youtube" style={styles.iconStyle}></i>
      </div> */}
      <nav>
        <Link style={styles.navLinks} to="/about">
          Our Work
        </Link>
        <Link style={styles.navLinks} to="/pricing">
          Pricing
        </Link>
        <Link style={styles.navLinks} to="/contact">
          Contact
        </Link>
      </nav>
    </header>
  );
};

const styles = {
  navLinks: {
    textDecoration: "none",
    color: "#25203a",
    paddingRight: 20,
  },
  headerStyle: {
    background: "white",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 40px",
  },
  logoStyle: {
    height: "50px", // Adjust the size as needed
    paddingRight: 20,
  },
  iconsStyle: {
    display: "flex",
    gap: "20px", // This will space out your icons
  },
  iconStyle: {
    fontSize: "24px", // Adjust the size as needed
    display: "block", // This will ensure that the icons don't disappear
  },
};

export default Header;
