import React from "react";
import "../Footer.css";

const Footer = () => {
  return (
    <footer className="sticky-footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Our Headquarters</h3>
          <p>New York NY 10016</p>
        </div>
        <div className="footer-section">
          <h3>Our Partners</h3>
          <img
            style={{ maxWidth: 100, height: 100, marginRight: 10 }}
            src="/partners/partner1.png"
            alt="Partner 1"
          />
          <img
            style={{ maxWidth: 100, height: 100, marginRight: 10 }}
            src="/partners/partner2.png"
            alt="Partner 2"
          />
          <img
            style={{ maxWidth: 100, height: 100, marginRight: 10 }}
            src="/partners/partner3.png"
            alt="Partner 3"
          />
          <img
            style={{ maxWidth: 100, height: 100, marginRight: 10 }}
            src="/partners/partner4.png"
            alt="Partner 4"
          />
        </div>
        <div className="footer-section subscribe-section">
          <h3>Subscribe to our Email newsletter</h3>
          <form>
            <input type="email" placeholder="Enter your email address" />
            <input type="submit" value="Subscribe" />
          </form>
          <p>Join 2,819 other subscribers</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>©2023 Hacky Apps. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
