import React from "react";
import "../App.css";

const AboutPage = () => {
  return (
    <div style={{ minHeight: "100vh", paddingTop: 100 }}>
      <div>
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          <div style={{ width: "500px", margin: "0 auto" }}>
            <div className="phone-image-container">
              <img
                src="/site-images/site-image-7.png"
                className="cutout-image"
                alt="phone-cutout"
              />
              <div className="moving-background"></div>
            </div>
          </div>
          <div style={{ maxWidth: 800, margin: "0 auto" }}>
            <h1 className="home-title" style={{ textAlign: "center" }}>
              About our work
            </h1>
            <div style={{ padding: 20, textAlign: "justify", fontSize: 20 }}>
              <p>
                Hacky Apps is a dynamic and innovative technology firm that
                specializes in developing cutting-edge applications, maintaining
                robust web pages, and providing comprehensive technical support.
                Founded by a team of passionate technologists and creative
                problem solvers, Hacky Apps has rapidly emerged as a leader in
                the digital landscape. The company prides itself on its ability
                to craft tailor-made solutions that seamlessly integrate
                user-friendly interfaces with powerful backend functionalities.
                Whether it's a mobile app designed for the consumer market or a
                complex enterprise-level web system, Hacky Apps approaches each
                project with a keen eye for detail and a commitment to
                delivering exceptional quality.
              </p>
              <p>
                One of the company’s major strengths lies in its commitment to
                quality. Hacky Apps has instituted rigorous quality control
                measures at every stage of the development process, ensuring
                that each product not only meets but exceeds industry standards.
                This dedication to excellence is reflected in the company's
                portfolio, which boasts a diverse range of projects from
                augmented reality apps to advanced e-commerce platforms. Their
                client testimonials often highlight the team's meticulous
                attention to detail and their ability to deliver projects on
                time, without compromising on functionality or design
                aesthetics.
              </p>
              <p>
                Hacky Apps also stands out for its wide range of technical
                projects, showcasing versatility and adaptability in an
                ever-evolving tech world. The company has successfully executed
                projects across various domains, including healthcare,
                education, finance, and entertainment, demonstrating its
                capacity to understand and cater to different industry needs.
                Their technical prowess extends beyond app development to
                include AI integration, cloud computing, and cybersecurity
                solutions, making them a one-stop-shop for all digital needs.
                This diversity in expertise not only attracts a broad clientele
                but also fosters a culture of continuous learning and innovation
                within the company, positioning Hacky Apps at the forefront of
                the tech industry.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
