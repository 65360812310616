import React, { useEffect, useState } from "react";
import "../App.css";

const HomePage = () => {
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    setAnimationStarted(true);
  }, []);

  return (
    <main>
      <div className="jumbo-splash">
        <img src="/jumbo.png" alt="tech innovation" />
        <div className="container">
          <div className={`text top ${animationStarted ? "slide-left" : ""}`}>
            delivered
          </div>
          <div
            className={`text bottom ${animationStarted ? "slide-right" : ""}`}
          >
            Your app needs
          </div>
        </div>
      </div>
      <section
        className="hero"
        style={{
          paddingTop: 100,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1 className="home-title">What we do</h1>
        <div className="image-container">
          <div className="image-box">
            <img src="/app-development.png" alt="Hacky apps" />
            <span className="overlay-text">Advanced App Development</span>
          </div>
          <div className="image-box">
            <img src="user-research.png" alt="Hacky apps" />
            <span className="overlay-text">Research Strategies</span>
          </div>
          <div className="image-box">
            <img src="app-design.png" alt="Hacky apps" />
            <span className="overlay-text">UI/UX Design and Branding</span>
          </div>
          <div className="image-box">
            <img src="app-creation.png" alt="Hacky apps" />
            <span className="overlay-text">App Production Monitoring</span>
          </div>
        </div>
      </section>

      <section className="features"></section>

      <section className="testimonials"></section>
    </main>
  );
};

export default HomePage;
