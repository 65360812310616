import React from "react";

const ContactPage = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <h1>More to come...</h1>
    </div>
  );
};

export default ContactPage;
