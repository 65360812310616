import React from "react";
import "../App.css";

const PricingPage = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <div className="jumbo-splash">
        <img src="/jumbo-pricing.png" alt="tech innovation" />
        <div className={`text top slide-right pricing`}>
          Our Pricing Strategy
        </div>
      </div>
      <div className="grid-container">
        <div className="grid-item">
          <div className="card-back">
            <p>
              App architecture services are priced according to the intricacy of
              the design and the technical requirements of the project. Hacky
              Apps offers initial consultations to assess client needs and then
              provides a detailed quote, ensuring transparency and alignment
              with the project's goals and budget constraints.
            </p>
          </div>
          <img src="/site-images/site-image-1.jpg" alt="App Architecture" />
          <div className="overlay">App Architecture</div>
        </div>
        <div className="grid-item">
          <div className="card-back">
            <p>
              Database management services are priced on a subscription basis,
              with rates varying depending on the scale of the database,
              frequency of maintenance required, and level of customization.
              This approach allows clients to choose a plan that aligns with
              their ongoing needs and budget.
            </p>
          </div>
          <img src="/site-images/site-image-2.jpg" alt="Database Management" />
          <div className="overlay">Database Management</div>
        </div>
        <div className="grid-item">
          <div className="card-back">
            <p>
              For web page design, Hacky Apps employs a tiered pricing
              structure. Basic packages cover essential design elements and
              functionality, suitable for small businesses or personal projects,
              while premium packages include advanced features, custom graphics,
              and interactive elements, tailored for larger enterprises or more
              complex requirements.
            </p>
          </div>
          <img src="/site-images/site-image-3.png" alt="Web Page Design" />
          <div className="overlay">Web Page Design</div>
        </div>
        <div className="grid-item">
          <div className="card-back">
            <p>
              Hacky Apps offers competitive pricing for its end-to-end
              development services, typically using a project-based model. Costs
              are determined based on the complexity, scope, and duration of the
              project, ensuring clients receive a comprehensive package that
              encompasses all stages of development from conception to
              deployment.
            </p>
          </div>
          <img
            src="/site-images/site-image-4.png"
            alt="End-to-end Development"
          />
          <div className="overlay">End-to-end Development</div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
